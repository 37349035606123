import { homepage } from "@/constant/Domain"
import { setStatus } from "@/redux/reducer/busU2diReducerSlice"
import { Button, theme } from "antd"
import { useDispatch } from "react-redux"
import { Link } from "react-router-dom"
import { FormOutlined } from "@ant-design/icons"
import "./index.scss"

import ModalPublish from "./modalPublish/Index"

const { useToken } = theme

const Header = () => {
  const { token } = useToken()
  const dispatch = useDispatch()

  const showModalPublish = () => {
    dispatch(
      setStatus({
        modalPublishOpen: true,
      } as any)
    )
  }

  return (
    <>
      <header className="comp-header">
        <div className="page-center wrap">
          <div className="logo">
            <Link to={`${homepage}/`}>
              <img src={`${homepage}/images/u2di/logo.svg`} />
            </Link>
          </div>
          <div className="feature">
            <div className="item">
              <Button
                type="primary"
                shape="round"
                icon={<FormOutlined />}
                size="small"
                onClick={() => showModalPublish()}
              >
                发布群
              </Button>
            </div>
          </div>
        </div>
      </header>

      <ModalPublish />
    </>
  )
}

export default Header
