import React, { useState } from "react"
import { Button, Form, Input, message } from "antd"
import { Link, useNavigate, useLocation } from "react-router-dom"
import { login, reg } from "@/server/user"
import "./index.scss"
import request from "@/utils/request"
import { homepage } from "@/constant/Domain"

function useQuery() {
  const { search } = useLocation()

  console.log("-search", search)

  return React.useMemo(() => new URLSearchParams(search), [search])
}

function Login() {
  const [isEthereum, setIsEthereum] = useState(!!window.ethereum)
  let navigate = useNavigate()
  let query = useQuery()

  const [form] = Form.useForm()

  const onFinish = (values: any) => {
    if (values.pwd === undefined) {
      message.warning("请输入密码")
      return
    }
    if (values.newPwd === undefined) {
      message.warning("请输入新密码")
      return
    }
    if (values.comfirPwd === undefined) {
      message.warning("请再次输入新密码")
      return
    }
    if (values.newPwd !== values.comfirPwd) {
      message.warning("二次输入的新密码不一致")
      return
    }
    request.post("/web/user/pwd", values).then((resp: any) => {
      if (resp.data.code === 200 && resp.data.data) {
        localStorage.clear()
        message.error("修改成功")
        navigate(`${homepage}/login`)
      }
    })
  }

  // const renderL = () => {
  //   let list = []
  //   // 2138974
  //   const min = 2138960
  //   const max = 2138980

  //   for (let i = min; i < max; i++) {
  //     list.push(
  //       <img
  //         src={`https://i.52112.com/icon/jpg/256/20190701/46079/${i}.jpg`}
  //         style={{ width: 100, height: 100 }}
  //       ></img>
  //     )
  //   }

  //   return list
  // }

  return (
    <div className="page-login">
      <div className="wrap">
        <div className="header">
          <h3>修改密码</h3>
        </div>
        <div className="form">
          <Form
            name="basic"
            form={form}
            layout="vertical"
            initialValues={{ remember: true }}
            onFinish={onFinish}
            autoComplete="off"
          >
            <Form.Item
              label=""
              name="pwd"
              rules={[{ required: true, message: "请输入旧密码!" }]}
            >
              <Input autoComplete="off" placeholder="原密码" type="password" />
            </Form.Item>
            <Form.Item
              label=""
              name="newPwd"
              rules={[{ required: true, message: "新输入新密码!" }]}
            >
              <Input autoComplete="off" placeholder="新密码" type="password" />
            </Form.Item>
            <Form.Item
              label=""
              name="comfirPwd"
              rules={[{ required: true, message: "请再次输入新密码!" }]}
            >
              <Input
                autoComplete="off"
                placeholder="再次输入新密码"
                type="password"
              />
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit" className="submit">
                修改密码
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  )
}

export default Login
