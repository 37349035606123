import { homepage } from "@/constant/Domain"
import { Pagination, Button, Space, Table } from "antd"
import { Link, useParams } from "react-router-dom"
import { RightOutlined } from "@ant-design/icons"
import "./index.scss"

import WgItem from "../component/wgitem"
import { useDispatch, useSelector } from "react-redux"
import {
  getList,
  selectBusU2diCates,
  selectBusU2diList,
  selectBusU2diStatus,
  setList,
  setStatus,
} from "@/redux/reducer/busU2diReducerSlice"
import { useEffect, useMemo, useState } from "react"
import QrcodeItem from "../component/qrcodeItem/index"
import request from "@/utils/request"

const Index = () => {
  const cates = useSelector(selectBusU2diCates)
  const { pageListLoading } = useSelector(selectBusU2diStatus)
  const [list, setList] = useState([])
  const [params, setParams] = useState({
    pageNum: 1,
    pageSize: 20,
    total: 0,
  })
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])

  const columns: any = [
    {
      title: "id",
      key: "id",
      dataIndex: "id",
    },
    {
      title: "img",
      dataIndex: "img",
      render: (_: any, record: any) => (
        <Space size="middle">
          <img src={`${homepage}/upload/${record.img}`} className="thumb" />
        </Space>
      ),
    },
    {
      title: "intro",
      dataIndex: "intro",
    },
  ]

  const dispatch = useDispatch()

  useEffect(() => {
    getList()
  }, [])

  const getList = () => {
    request.post("/web/news/list/", params).then((resp: any) => {
      if (resp.data.code == 200 && resp.data.data) {
        setList(resp.data.data.list)
        setParams({
          pageNum: 1,
          pageSize: 20,
          total: resp.data.data.total,
        })
      }
    })
  }

  const onChange = (pageNum: number, pageSize: number) => {
    setParams({
      pageNum,
      pageSize,
      total: params.total,
    })
    getList()
  }

  const onDelete = () => {
    console.log("----", selectedRowKeys)

    request.post("/web/news/del/multi", { ids: selectedRowKeys }).then(() => {
      getList()
    })
  }

  const onPaginationChange = (page: number, pageSize: number) => {
    // dispatch(setStatus({ homeListLoading: true } as any))
    // dispatch(
    //   getList({
    //     pid: urlParams.id,
    //     pageNum: page,
    //     pageSize: pageSize,
    //   }) as any
    // )
    window.scrollTo(0, 0)
  }

  const onSelectChange = (newSelectedRowKeys: any) => {
    setSelectedRowKeys(newSelectedRowKeys)
  }

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  }

  return (
    <div className="page-list page-remove">
      <div className="feature">
        <Button type="primary" onClick={() => onDelete()}>
          删除
        </Button>
      </div>
      <div className="list">
        <Table
          rowKey={(record: any) => record.id}
          rowSelection={rowSelection}
          columns={columns}
          dataSource={list}
        />
      </div>

      <div className="cates-pagination">
        <Pagination
          current={params.pageNum}
          pageSize={params.pageSize}
          total={params.total}
          showSizeChanger={false}
          onChange={onPaginationChange}
        />
      </div>
    </div>
  )
}

export default Index
