import { ConfigProvider, Breadcrumb, Layout, Menu, theme } from "antd"
import { isAndroid, isMobile } from "react-device-detect"

import {
  StyleProvider,
  legacyLogicalPropertiesTransformer,
} from "@ant-design/cssinjs"

import { Outlet, useNavigate } from "react-router-dom"
import { useEffect } from "react"
import custTheme from "@/constant/theme"
import { useDispatch } from "react-redux"
import U2diHeader from "@/features/u2di/component/Header"
import ModalDetail from "@/features/u2di/component/modalDetail/Index"

function App() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { Header, Content, Footer } = Layout

  // 全局初始化
  useEffect(() => {
    seBodyWechatpAppInAndroid()
  }, [])

  // 默认算法 theme.defaultAlgorithm、暗色算法 theme.darkAlgorithm 和紧凑算法 theme.compactAlgorithm
  const seBodyWechatpAppInAndroid = () => {
    const isWechat = /MicroMessenger/i.test(window.navigator.userAgent)
    if (isMobile) {
      if (isWechat && isAndroid) {
        document.getElementsByTagName("body")[0].className =
          "wechatpAppInAndroid"
      }
    }
  }

  return (
    <StyleProvider
      transformers={[legacyLogicalPropertiesTransformer]}
      hashPriority="high"
    >
      <ConfigProvider
        theme={{
          algorithm: theme.defaultAlgorithm,
          token: custTheme,
        }}
      >
        <Layout className={isMobile ? "layout app mapp" : "layout app"}>
          <U2diHeader></U2diHeader>
          <Content>
            <div className="page-center">
              <Outlet />
            </div>
          </Content>
          <Footer style={{ textAlign: "center" }}>
            Copyright © 2023 <a href="https://u2di.com/">优土地</a> All Rights
            Reserved.
          </Footer>
          <ModalDetail />
        </Layout>
      </ConfigProvider>
    </StyleProvider>
  )
}

export default App
