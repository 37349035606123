import { message } from "antd"
import axios from "axios"

const instance = axios.create({
  baseURL:
    window.location.host.indexOf("localhost") > -1
      ? "http://localhost:1801"
      : // "https://www.zsmicroelec.com/"
        "https://qunlve.com",
  timeout: 15000,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    // @ts-ignore:next-line
    token: global.token || localStorage.getItem("token") || "",
  },
})

instance.interceptors.request.use(
  function (config: any) {
    config.token = localStorage.getItem("token") || ""
    return config
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error)
  }
)

instance.interceptors.response.use(
  function (response) {
    console.log("responseresponse", response)
    if (response.status == 200 && response.data) {
      if (response.data.code == 500 && response.data.message) {
        message.error(response.data.message)
      }
      if (response.data.code == 401) {
        message.error(response.data.message || "token invaild!")

        // if (window.location.host.indexOf("localhost") > -1) {
        //   window.location.href = "/login"
        // } else {
        //   window.location.href = "/"
        // }
      }
      return response
    }

    return response
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    console.log("-----response error", error)
    if (error.message == "Request failed with status code 401") {
      // wx.miniProgram.redirectTo({
      //   url: "/pageVip/login/index",
      // })
    }
    return Promise.reject(error)
  }
)

export default instance
