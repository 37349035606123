import React from "react"
import { Upload, Modal, Spin } from "antd"
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons"
import { imageUrl, domain } from "@/constant/Domain"

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })
}

class PicturesWall extends React.Component {
  state = {
    loading: true,
    previewVisible: false,
    previewImage: "",
    fileList: [],
    defaultFileList: [],
  }

  handleCancel = () => this.setState({ previewVisible: false })

  handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj)
    }

    this.setState({
      previewImage: file.url || file.preview,
      previewVisible: true,
    })
  }

  componentDidMount() {
    let list = this.props.value
    console.log("PicturesWall", this.props.value, this.props.limit)
    if (this.props.value) {
      if (this.props.limit == 1) {
        this.setState({
          fileList: [
            {
              uid: "1",
              name: "",
              status: "done",
              url: imageUrl + list[0],
            },
          ],
          loading: false,
        })
      } else {
        let temp = []
        list.forEach((item, index) => {
          temp.push({
            uid: index,
            name: "",
            status: "done",
            url: imageUrl + item,
          })
        })
        this.setState({
          fileList: temp,
          loading: false,
        })
      }
    } else {
      this.setState({
        loading: false,
      })
    }
  }

  handleChange = ({ fileList }) => {
    this.setState({ fileList })
    let temp = []
    fileList.forEach((item) => {
      if (item.status == "done") {
        temp.push(item.response.data)
      }
      if (item.status == "removed") {
      }
    })
    if (this.props.limit == 1) {
      temp = temp[0] || ""
    }
    this.props.onChange(temp)
  }

  render() {
    const { previewVisible, previewImage, fileList, defaultFileList } =
      this.state
    let url = domain + "/web/main/upload/image"
    // if (location.hostname == "localhost") {
    //   url = "http://coral.wanfow.cn" + "/web/main/upload/image"
    // }

    const uploadButton = (
      <div>
        <PlusOutlined />
        <div className="ant-upload-text">{this.props.desc || "选择照片"}</div>
      </div>
    )
    if (this.state.loading) {
      return <Spin></Spin>
    }
    return (
      <div className="clearfix">
        <Upload
          action={url}
          // action="http://localhost:1802/api/admin/upload/image"
          listType="picture-card"
          fileList={fileList}
          defaultFileList={defaultFileList}
          onPreview={this.handlePreview}
          onChange={this.handleChange}
          multiple={this.props.limit === 1 ? false : true}
          data={{
            type: this.props.type || "general",
          }}
          headers={{
            enctype: "multipart/form-data",
            Token: localStorage.getItem("token"),
          }}
        >
          {fileList.length >= (this.props.limit || 8) ? null : uploadButton}
        </Upload>
        <Modal
          visible={previewVisible}
          footer={null}
          onCancel={this.handleCancel}
        >
          <img alt="example" style={{ width: "100%" }} src={previewImage} />
        </Modal>
      </div>
    )
  }
}

export default PicturesWall
