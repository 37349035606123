import { homepage } from "@/constant/Domain"
import {
  selectBusU2diStatus,
  setStatus,
} from "@/redux/reducer/busU2diReducerSlice"
import { Modal } from "antd"
import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import Form from "./Form"
import "./index.scss"

const Index = () => {
  const { modalPublishOpen, publishConfirmLoading } =
    useSelector(selectBusU2diStatus)
  const [modalText, setModalText] = useState("Content of the modal")

  const dispatch = useDispatch()

  const handleOk = () => {
    setModalText("The modal will be closed after two seconds")
    handleStatus({ publishConfirmLoading: true })
    setTimeout(() => {
      handleStatus({ modalPublishOpen: false, publishConfirmLoading: false })
    }, 2000)
  }

  const handleCancel = () => {
    handleStatus({ modalPublishOpen: false })
  }

  const handleStatus = (status: any) => {
    dispatch(setStatus(status))
  }

  return (
    <>
      <Modal
        title="发布群"
        open={modalPublishOpen}
        confirmLoading={publishConfirmLoading}
        onCancel={handleCancel}
        footer={null}
        className="publish-modal"
      >
        <Form />
      </Modal>
    </>
  )
}

export default Index
