import React, { useState } from "react"
import { Button, Form, Input, message } from "antd"
import { Link, useNavigate, useLocation } from "react-router-dom"
import { login, reg } from "@/server/user"
import LoginWallet from "./LoginWallet"
import "./index.scss"
import PandaSvg from "@/components/icon/pandaSvg"
import request from "@/utils/request"

function useQuery() {
  const { search } = useLocation()

  console.log("-search", search)

  return React.useMemo(() => new URLSearchParams(search), [search])
}

function Login() {
  const [isEthereum, setIsEthereum] = useState(!!window.ethereum)
  let navigate = useNavigate()
  let query = useQuery()

  const [form] = Form.useForm()

  const onFinish = (values: any) => {
    request.post("/web/user/login", values).then((resp: any) => {
      if (resp.data.code === 200 && resp.data.data) {
        localStorage.setItem("token", resp.data.data)

        // 改成 axios token 以防止不成功
        /* @ts-ignore */
        request.defaults.headers.token = resp.data.data

        let target = query.get("target")
        if (target) {
          navigate("" + target)
        } else {
          navigate("/remove")
        }
        // window.location.href = "/"
      }
    })
  }

  // const renderL = () => {
  //   let list = []
  //   // 2138974
  //   const min = 2138960
  //   const max = 2138980

  //   for (let i = min; i < max; i++) {
  //     list.push(
  //       <img
  //         src={`https://i.52112.com/icon/jpg/256/20190701/46079/${i}.jpg`}
  //         style={{ width: 100, height: 100 }}
  //       ></img>
  //     )
  //   }

  //   return list
  // }

  return (
    <div className="page-login">
      <div className="wrap">
        <div className="header">
          {/* <div className="icon">
            <PandaSvg />
          </div> */}
          <h3>Welcome Back!</h3>
        </div>
        <div className="form">
          <Form
            name="basic"
            form={form}
            layout="vertical"
            initialValues={{ remember: true }}
            onFinish={onFinish}
            autoComplete="off"
          >
            <Form.Item label="" name="username" required={true}>
              <Input autoComplete="off" placeholder="Username" />
            </Form.Item>
            <Form.Item label="" name="password" required={true}>
              <Input
                autoComplete="off"
                placeholder="Password"
                type="password"
              />
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit" className="submit">
                Login
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  )
}

export default Login
