import ReactDOM from "react-dom/client"

import "./assets/style/index.scss"

import reportWebVitals from "./reportWebVitals"
import { BrowserRouter, Routes, Route } from "react-router-dom"

import { Provider } from "react-redux"
import { store } from "@/redux/store"

import U2diLayout from "./U2diLayout"
import U2diHome from "./features/u2di/index/Index"
import U2diList from "./features/u2di/list/Index"
import U2diListRemove from "./features/u2di/list/Remove"
import Login from "./features/user/Login"
import Pwd from "./features/user/Pwd"

import { homepage } from "./constant/Domain"

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)

const renderRouter = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={`${homepage}/`} element={<U2diLayout />}>
          <Route index element={<U2diHome />} />
          <Route path={`${homepage}/login`} element={<Login />} />
          <Route path={`${homepage}/pwd`} element={<Pwd />} />
          <Route path={`${homepage}/list/:id`} element={<U2diList />} />
          <Route path={`${homepage}/remove`} element={<U2diListRemove />} />
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

root.render(<Provider store={store}>{renderRouter()}</Provider>)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
