import React, { useEffect, useState } from "react"
import { InboxOutlined, UploadOutlined } from "@ant-design/icons"
import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  InputNumber,
  message,
  Radio,
  Rate,
  Row,
  Select,
  Slider,
  Switch,
  Upload,
} from "antd"

import "./index.scss"
import TextArea from "antd/es/input/TextArea"
import { useDispatch, useSelector } from "react-redux"
import {
  selectBusU2diCates,
  selectBusU2diStatus,
  setStatus,
} from "@/redux/reducer/busU2diReducerSlice"
import PicturesWall from "@/components/upload/Image"
import request from "@/utils/request"
import { domain } from "@/constant/Domain"

const { Option } = Select

const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 14 },
}

const normFile = (e: any) => {
  console.log("Upload event:", e)
  if (Array.isArray(e)) {
    return e
  }
  return e?.fileList
}

const AppForm: React.FC = () => {
  const [cateDesc, setCateDesc] = useState<string>("")
  const [time, setTime] = useState<number>(new Date().getTime())
  const cates = useSelector(selectBusU2diCates)
  const { modalPublishOpen, publishConfirmLoading } =
    useSelector(selectBusU2diStatus)

  const dispatch = useDispatch()
  const [form] = Form.useForm()

  useEffect(() => {
    // curid 不一样时
    if (modalPublishOpen) {
      resetForm()
    }
  }, [modalPublishOpen])

  const closeModalPublish = () => {
    dispatch(
      setStatus({
        modalPublishOpen: true,
      } as any)
    )
  }

  const onFinish = (values: any) => {
    console.log("Received values of form: ", values)
    values.t = time
    request.post("/web/news/add", values).then((resp: any) => {
      if (resp.data.code == 200) {
        handleCancel()
        resetForm()
      }
    })
  }

  const resetForm = () => {
    form.resetFields()
  }

  const handleCancel = () => {
    handleStatus({ modalPublishOpen: false })
  }

  const handleStatus = (status: any) => {
    dispatch(setStatus(status))
  }

  const onFieldsChange = (fields: any) => {
    if (fields[0].name == "cate") {
      cates.forEach((item: any) => {
        if (fields[0].value == item.name) {
          setCateDesc(item.desc)
        }
      })
    }
  }

  const renderCates = () => {
    let temp: any = []
    cates.forEach((item: any) => {
      temp.push(
        <Option value={item.id} key={item.name}>
          {item.name}
        </Option>
      )
    })
    return temp
  }

  return (
    <Form
      className="modal-publish-form"
      form={form}
      {...formItemLayout}
      onFinish={onFinish}
      onFieldsChange={onFieldsChange}
      style={{ maxWidth: 600 }}
      initialValues={{
        date: 1,
      }}
    >
      <Form.Item
        label="二维码"
        name="img"
        rules={[{ required: true, message: "请上传微信群二维码!" }]}
      >
        <PicturesWall
          desc="选择群二维码"
          type={localStorage.getItem("name") || "qrcode"}
          limit="1"
        />
      </Form.Item>

      {/* <Form.Item label="二维码">
        <Form.Item
          name="dragger"
          valuePropName="fileList"
          getValueFromEvent={normFile}
          noStyle
        >
          <Upload.Dragger name="files" action="/upload.do">
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-hint">单击或拖动文件到此区域进行上传</p>
          </Upload.Dragger>
        </Form.Item>
      </Form.Item> */}
      <Form.Item
        name="pid"
        label="群分类"
        extra={cateDesc}
        hasFeedback
        rules={[{ required: true, message: "请选择您需要发布到分类!" }]}
      >
        <Select placeholder="请选择分类">{renderCates()}</Select>
      </Form.Item>
      <Form.Item
        name="intro"
        label="群介绍"
        hasFeedback
        rules={[{ required: true, message: "请输入群介绍!" }]}
      >
        <Input type="text" maxLength={32} placeholder="请输入群介绍" />
      </Form.Item>
      <Form.Item name="tagId" label="保鲜期">
        <Radio.Group>
          <Radio value={1}>1天</Radio>
          <Radio value={3}>3天</Radio>
          <Radio value={7}>7天</Radio>
        </Radio.Group>
      </Form.Item>

      <Form.Item label="验证码">
        <Row gutter={8}>
          <Col span={12}>
            <Form.Item
              name="tag"
              noStyle
              rules={[{ required: true, message: "请选择验证码!" }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            {/* <Button>获取验证码</Button> */}
            <img
              onClick={() => setTime(new Date().getTime())}
              src={`${domain}/web/main/captcha/image?t=${time}`}
              className="captcha"
            />
          </Col>
        </Row>
      </Form.Item>

      <Form.Item
        wrapperCol={{ span: 24 }}
        style={{ display: "flex", justifyContent: "center" }}
      >
        <Button type="default" onClick={handleCancel}>
          取消
        </Button>
        <Button type="primary" htmlType="submit" style={{ marginLeft: 12 }}>
          发布
        </Button>
      </Form.Item>
    </Form>
  )
}

export default AppForm
