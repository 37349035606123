import { Skeleton } from "antd"
import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import "./index.scss"

function Wgitem() {
  return (
    <div className="comp-wgitem">
      <Skeleton.Image active />
      <Skeleton active paragraph={true} round className="skeleton" />
    </div>
  )
}

export default Wgitem
