import { domain, homepage } from "@/constant/Domain"
import {
  selectBusU2diStatus,
  setStatus,
  selectBusU2diDetail,
} from "@/redux/reducer/busU2diReducerSlice"
import { Modal } from "antd"
import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import "./index.scss"

const Index = () => {
  const { modalQrcodeDetailOpen } = useSelector(selectBusU2diStatus)
  const detail = useSelector(selectBusU2diDetail)
  const [modalText, setModalText] = useState("Content of the modal")

  const dispatch = useDispatch()

  const handleCancel = () => {
    handleStatus({ modalQrcodeDetailOpen: false })
  }

  const handleStatus = (status: any) => {
    dispatch(setStatus(status))
  }

  const rednerDetail = () => {
    return (
      <div className="modal-qrcode-detail">
        <div className="qrcode">
          <img src={`${domain}upload/${detail.img}`} alt="" />
        </div>
        <p className="intro">{detail.intro}</p>
        <p className="date">
          <span>保鲜期：</span>
          <strong>{detail.tagId}天</strong>
        </p>
      </div>
    )
  }

  return (
    <>
      <Modal
        title="查看二维码"
        open={modalQrcodeDetailOpen}
        onCancel={handleCancel}
        footer={null}
      >
        {modalQrcodeDetailOpen && rednerDetail()}
      </Modal>
    </>
  )
}

export default Index
