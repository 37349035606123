import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { add, remove, update, post } from "@/server/baseApi"
import request from "@/utils/request"
import dayjs from "dayjs"

const cates = [
  {
    id: 1,
    name: "搞钱类",
    desc: "各类以挣钱为目标的群，如招聘，应聘，兼职，直播，投资，理财，保险，创业等等 ",
  },
  {
    id: 2,
    name: "工程类",
    desc: "各类工程链上的交流群，如土地，房产，设计，施工，投融，代建，营销等等 ",
  },

  {
    id: 3,
    name: "产业类",
    desc: "各产业类的群，包括但不限于机器人，大数据，物联网，无人机，新材料等等 ",
  },

  {
    id: 4,
    name: "技术类",
    desc: "各种技术交流的群，如软件开发，幼儿教育，家具制作，农业种植，鉴别收藏等等 ",
  },

  {
    id: 5,
    name: "情感类",
    desc: "各类汇聚情感喜好的群，如追星，搭讪，约会，婚姻，亲子，吐槽，同性等等 ",
  },

  {
    id: 6,
    name: "生活类",
    desc: "以上分类之外的群，如兴趣爱好，数码硬件，购房看房，旅游出行，学习考试等等",
  },
]

export const busU2diSlice = createSlice({
  name: "busU2di",
  initialState: {
    cates: cates,
    list: {
      list: [],
      pageNum: 1,
      pageSize: 40,
      total: 0,
      totalPage: 0,
    },
    topList: {},
    detail: {}, // 详情数据
    status: {
      modalPublishOpen: false,
      modalQrcodeDetailOpen: false,
      publishConfirmLoading: false,
      homeListLoading: true,
      pageListLoading: true,
    },
  },
  reducers: {
    setList: (state, action: any) => {
      state.list = action.payload
    },
    setTopList: (state, action: any) => {
      state.topList = action.payload
    },
    setDetail: (state, action: any) => {
      state.detail = action.payload
    },
    setStatus: (state, action: any) => {
      let status = Object.assign({}, state.status, action.payload)
      state.status = status
    },
  },
})

const diffDay = (date: string) => {
  const targetDate = dayjs(date) // 给定日期
  const currentDate = dayjs() // 当前日期

  return targetDate.diff(currentDate, "day") // 计算相差天数
}

export const { setList, setTopList, setDetail, setStatus } =
  busU2diSlice.actions

export const selectBusU2diCates = (state: { busU2di: { cates: any } }) =>
  state.busU2di.cates || []

export const selectBusU2diList = (state: { busU2di: { list: any } }) =>
  state.busU2di.list || []

export const selectBusU2diTopList = (state: { busU2di: { topList: any } }) =>
  state.busU2di.topList || []

export const selectBusU2diDetail = (state: { busU2di: { detail: any } }) =>
  state.busU2di.detail || {}

export const selectBusU2diStatus = (state: { busU2di: { status: any } }) =>
  state.busU2di.status || {}

export const setModalDetail =
  (data: any = {}) =>
  (dispatch: any) => {
    console.log("-----datra", data)

    dispatch(setDetail(data))
    dispatch(setStatus({ modalQrcodeDetailOpen: data ? true : false } as any))
  }

export const getTopList =
  (data: any = {}) =>
  (dispatch: any) => {
    request("/web/news/list/top", {}).then((resp: any) => {
      if (resp.data.code == 200 && resp.data.data) {
        // dispatch(setList(resp.data.data.list))
        let temp: any = {}
        resp.data.data.forEach((item: any) => {
          if (!temp[item.pid]) {
            temp[item.pid] = []
          }
          if (item.releaseTime) {
            item.tagId = diffDay(item.releaseTime) + 1
          }
          temp[item.pid].push(item)
        })
        dispatch(setTopList(temp))
        dispatch(setStatus({ homeListLoading: false } as any))
      }
    })
  }

export const getList =
  (data: any = {}) =>
  (dispatch: any) => {
    request.post("/web/news/list", data).then((resp: any) => {
      if (resp.data.code == 200 && resp.data.data) {
        resp.data.data.list.forEach((item: any) => {
          if (item.releaseTime) {
            item.tagId = diffDay(item.releaseTime) + 1
          }
        })
        dispatch(setList(resp.data.data))
        dispatch(setStatus({ pageListLoading: false } as any))
      }
    })
  }

export default busU2diSlice.reducer
