import { domain, homepage } from "@/constant/Domain"
import {
  selectBusU2diStatus,
  setStatus,
  selectBusU2diDetail,
  setModalDetail,
} from "@/redux/reducer/busU2diReducerSlice"
import { Modal } from "antd"
import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import "./index.scss"

const Index = (props: any) => {
  const dispatch = useDispatch()
  const { item } = props

  const showDetail = (item: any) => {
    dispatch(setModalDetail(item) as any)
  }

  return (
    <div className="wrap" onClick={() => showDetail(item)}>
      <div
        className="qrcode"
        style={{
          backgroundImage: `url(${domain}/upload/${item.img})`,
        }}
      ></div>
      <div className="intro">
        <p>{item.intro}</p>
        <div className="date">
          <span>保鲜期：</span>
          <strong>{item.tagId}天</strong>
        </div>
      </div>
    </div>
  )
}

export default Index
