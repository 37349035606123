import { domain, homepage } from "@/constant/Domain"
import { Pagination, Empty } from "antd"
import { Link, useParams } from "react-router-dom"
import { RightOutlined } from "@ant-design/icons"
import "./index.scss"

import WgItem from "../component/wgitem"
import { useDispatch, useSelector } from "react-redux"
import {
  getList,
  selectBusU2diCates,
  selectBusU2diList,
  selectBusU2diStatus,
  setList,
  setStatus,
} from "@/redux/reducer/busU2diReducerSlice"
import { useEffect, useMemo, useState } from "react"
import QrcodeItem from "../component/qrcodeItem/index"

const Index = () => {
  const urlParams = useParams()
  const cates = useSelector(selectBusU2diCates)
  const { pageListLoading } = useSelector(selectBusU2diStatus)
  const list = useSelector(selectBusU2diList)

  const dispatch = useDispatch()

  useEffect(() => {
    onPaginationChange(1, 40)
    return () => {
      const temp: any = {
        list: [],
        pageNum: 1,
        pageSize: 40,
        total: 0,
        totalPage: 0,
      }
      dispatch(setList(temp))
    }
  }, [])

  const onPaginationChange = (page: number, pageSize: number) => {
    dispatch(setStatus({ homeListLoading: true } as any))
    dispatch(
      getList({
        pid: urlParams.id,
        pageNum: page,
        pageSize: pageSize,
      }) as any
    )
    window.scrollTo(0, 0)
  }

  const item = useMemo(() => {
    return cates.find((item: any) => item.id === Number(urlParams.id))
  }, [cates, urlParams.id])

  const renderSkeleton = () => {
    return [1, 2, 3, 4, 5].map((item: any) => (
      <div className="item wgitem" key={item}>
        <WgItem />
      </div>
    ))
  }

  const renderItem = () => {
    if (list.list.length > 0) {
      return list.list.map((item: any) => (
        <div className="item wgitem" key={item}>
          <QrcodeItem item={item}></QrcodeItem>
        </div>
      ))
    }

    return (
      <div className="empty-list">
        <Empty description="暂无数据" />
      </div>
    )
  }

  return (
    <div className="page-list">
      <div className="banner">
        <div
          className="img"
          style={{
            backgroundImage: `url(${domain}/images/u2di/listban.jpg)`,
          }}
        >
          <div className="cateinfo">
            <h3>{item.name}</h3>
            <p>{item.desc}</p>
          </div>
        </div>
      </div>

      <div className="cates-list">
        {pageListLoading ? (
          <div className="list">{renderSkeleton()}</div>
        ) : (
          <div className="list">{renderItem()}</div>
        )}
      </div>

      <div className="cates-pagination">
        {list.list.length > 0 && (
          <Pagination
            current={list.pageNum}
            pageSize={list.pageSize}
            total={list.total}
            showSizeChanger={false}
            onChange={onPaginationChange}
          />
        )}
      </div>
    </div>
  )
}

export default Index
