import { homepage } from "@/constant/Domain"
import { Skeleton, Empty } from "antd"
import { Link } from "react-router-dom"
import { RightOutlined } from "@ant-design/icons"
import "./index.scss"

import WgItem from "../component/wgitem"
import { useDispatch, useSelector } from "react-redux"
import {
  getTopList,
  selectBusU2diTopList,
  selectBusU2diCates,
  selectBusU2diStatus,
  setStatus,
} from "@/redux/reducer/busU2diReducerSlice"
import { useEffect } from "react"
import { url } from "inspector"
import QrcodeItem from "../component/qrcodeItem"

const Index = () => {
  const cates = useSelector(selectBusU2diCates)
  const topList = useSelector(selectBusU2diTopList)
  const { homeListLoading } = useSelector(selectBusU2diStatus)

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setStatus({ homeListLoading: true } as any))
    dispatch(getTopList() as any)
  }, [])

  const renderSkeleton = () => {
    return [1, 2, 3, 4, 5].map((item: any) => (
      <div className="item wgitem" key={item}>
        <WgItem />
      </div>
    ))
  }

  const renderItem = (pid: number) => {
    if (topList[pid]) {
      return topList[pid].map((item: any) => (
        <div className="item wgitem" key={item}>
          <QrcodeItem item={item}></QrcodeItem>
        </div>
      ))
    }
    return (
      <div className="empty-list">
        <Empty description="" />
      </div>
    )
  }

  const renderCateList = () => {
    let temp: any = []
    cates.forEach((item: any) => {
      temp.push(
        <div className="cates-list" key={item.id}>
          <div className="info">
            <div className="title">
              <h3>{item.name}</h3>
              <p>{item.desc}</p>
            </div>
            <div className="more">
              <Link to={`${homepage}/list/${item.id}`}>
                更多
                <RightOutlined />
              </Link>
            </div>
          </div>
          {homeListLoading ? (
            <div className="list">{renderSkeleton()}</div>
          ) : (
            <div className="list">{renderItem(item.id)}</div>
          )}
        </div>
      )
    })
    return temp
  }

  const renderCatesthumb = () => {
    let temp: any = []
    cates.forEach((item: any) => {
      temp.push(
        <Link to={`${homepage}/list/${item.id}`} className="item" key={item.id}>
          {item.name}
        </Link>
      )
    })
    return temp
  }

  return (
    <div className="page-index">
      <div className="banner">
        <div
          className="img"
          style={{
            backgroundImage: `url(${homepage}/images/u2di/banner.png)`,
          }}
        ></div>
      </div>
      <div className="catesthumb">{renderCatesthumb()}</div>
      {renderCateList()}
    </div>
  )
}

export default Index
